.button {
  background-color: #FFF4DE;
  color: #FFA800;
  border-color: rgba(#FFA800, .35);
  font-weight: 500;
  &:hover, &:focus {
    background-color: #fff;
    color: #FFA800;
    border-color: #FFA800; } }

.typography {
  max-width: 95%; }

.premiumUser {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1; }

.contactType {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0; }

.padArchiveContnet {
  min-width: 240px; }

.submittersList {
  display: flex;
  flex-wrap: wrap;
  a:not(:last-of-type) {
    &::after {
      content: ","; } } }
