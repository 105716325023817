.draggable-rows-table {
  table-layout: auto;
  .drag-head {
    color: transparent;
    user-select: none;
    pointer-events: none;
  }

  .drag-cell {
    padding: 16px 0 16px 16px;
  }

  .draggable-table-btn {
    outline: none;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: grab;
    color: rgba(#000, 0.5);
    transition: 0.15s ease;
    svg {
      width: 15px;
      height: 15px;
    }
    &:hover {
      color: #000;
    }
  }

  .ant-table-row.is-dragging {
    opacity: 0;
  }
}
