@import "../../sass/utils";

.root {
  min-height: 100vh; }

.statusBar {
  background-color: $white;
  border-bottom: 1px dashed #f0f0f0;
  padding: 7px 50px;
  height: 74px; }

.statusBarContainer {
  @extend %sm-container; }

.statusCounter {
  font-size: 12px;
  margin: 0 auto;
  td {
    padding: 0 10px;
    a {
      color: #000000d9;
      &:hover {
        text-decoration: underline; } } }
  & {
    td:nth-child(odd) {
      min-width: 160px;
      font-weight: 500; }
    td:nth-child(even) {
      min-width: 30px; } } }

.header {
  background-color: $white;
  z-index: map-get($z-index-list, '1001');
  &.isSticky {
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    animation: slideInUp 0.2s;
    @keyframes slideInUp {
      from {
        transform: translate3d(0, -100%, 0);
        visibility: visible; }
      to {
        transform: translate3d(0, 0, 0); } }
    & ~ * {
      margin-top: 3rem; } } }

.loadingBar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: map-get($z-index-list, '1002'); }

.headerContainer {
  background-color: $white;
  height: 139px; } // 64 + 75

.menuContainer {
  @extend %sm-container;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.content {
  margin-top: 2rem;
  padding: 0 50px;
  min-height: calc(100vh - 100px); }

.profileAvatar {
  cursor: pointer;
  &:global(.active) {
    background-color: $primary-color; } }

.backTop {
  right: 15px;
  bottom: 15px; }
