.data-row-item {
  &:not(:last-of-type) {
    margin-bottom: 32px;
  }
}

.data-row-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 32px;
}

.data-row-item .ant-form-item {
  margin-bottom: 0;
  flex-direction: column;
  position: relative;
  min-height: 32px;
  .ant-form-item-label {
    text-align: left;
    position: absolute;
    bottom: 100%;
    padding: 0;
    & > label {
      height: unset;
      font-size: 14px;
      font-weight: 500;
    }
  }
}

.data-row-item {
  &.textarea,
  &.tags_input,
  &.contacts {
    .ant-form-item {
      height: unset;
    }
  }
  .ant-form-item.data-row-tags {
    height: unset;
  }
}

.data-row-label {
  align-self: flex-start;
  padding-top: 4px;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}

.data-row-content {
  font-size: 14px;
  flex-grow: 1;
  border-bottom: 1px solid #000;
  padding-bottom: 4px;
  padding-top: 2px;
  padding: 4px 8px 2px;
  min-height: 29px;
  overflow: hidden;
}

.data-row-tags {
  padding: 6px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid rgb(217, 217, 217);
}

.data-row-meta {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  svg {
    width: 20px;
    height: 20px;
  }
  .ant-checkbox-wrapper {
    zoom: 1.2;
  }
  &.prev {
    clip-path: polygon(75% 0, 100% 50%, 75% 100%, 0 100%, 0 0);
    .ant-checkbox-wrapper {
      margin-right: 10px;
    }
    &.is-conflict {
      background-color: #f7c4c4;
    }
  }
  &.next {
    flex-direction: row-reverse;
    clip-path: polygon(100% 0, 100% 100%, 25% 100%, 0 50%, 25% 0);
    .ant-checkbox-wrapper {
      margin-left: 10px;
    }
    &.is-conflict {
      background-color: #c4f7c4;
    }
  }
}

.data-row-info {
  &.prev {
    padding-left: 8px;
    border-radius: 4px 0 0 4px;
    text-align: right;
    .data-row-label {
      margin-right: 6px;
    }
    &.is-conflict {
      background-color: #f7c4c4;
    }
  }
  &.next {
    padding-right: 8px;
    flex-direction: row-reverse;
    border-radius: 0 4px 4px 0;
    .data-row-label {
      margin-left: 6px;
    }
    &.is-conflict {
      background-color: #c4f7c4;
    }
  }
}

.data-row-static-extra {
  margin-top: 10px;
  border-bottom: 1px solid #000;
  &.next {
    text-align: right;
  }
}

.data-row-contacts {
  position: relative;
  .contacts-label {
    position: absolute;
    bottom: 100%;
    font-size: 14px;
    font-weight: 500;
  }
  .row-contacts-item {
    background-color: #fff;
    border: 1px solid rgb(217, 217, 217);
    border-radius: 5px;
    padding: 6px 12px;
  }
}

.row-contacts-item {
  text-align: left;
  position: relative;
  min-height: 72px;
  .row-contacts-remove {
    position: absolute;
    right: 6px;
    top: 6px;
  }
  li {
    overflow: hidden;
    display: flex;
  }
  .label {
    font-weight: 500;
    min-width: 50px;
  }
  .value {
    flex-grow: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}

.dupe-resolver-footer {
  margin-top: 50px;
  margin-bottom: 50px;
}

.dupe-resolver-map {
  .gm-bundled-control .gmnoprint {
    transform: scale(0.7);
    transform-origin: right bottom;
  }
  .gm-fullscreen-control {
    transform: scale(0.7);
    transform-origin: right top;
  }
  .gm-style-mtc {
    transform: scale(0.7);
    transform-origin: left top;
  }
  .gm-style-mtc:last-child {
    margin-left: -20px;
  }
}
