@import "../../../../sass/utils";

.timelineContainer {
  border-color: $normal-color;
  display: flex;
  flex-direction: column;
  min-height: 220px;
  &__custom {
    min-height: unset;
     :global(.ant-card-body) {
      padding: 0; } }
   :global(.ant-card-head-title) {
    flex-grow: 1;
    padding: 12px 0; }
   :global(.ant-card-body) {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 607px;
    min-height: 0; }
   :global(.ant-timeline-item-last > .ant-timeline-item-content) {
    min-height: auto; } }

.timeline {
  padding-top: 12px; }

.owners {
  min-height: 218px;
  max-height: 397px;
  overflow-y: auto; }

.internalNotes {
  min-height: 194px; }

.reportedImage {
  max-width: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__btns {
    align-self: flex-end;
    margin-top: 10px; }
  img {
    max-width: 100%;
    max-height: 360px; } }

.createOwner {
  .ant-modal-wrap > .ant-modal {
    min-width: 380px; } }
