.survey-pad__view__upload-card {
  width: 100%;
  max-width: 200px;
}

.upload-card {
  &__title {
    color: #000;
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 10px;
  }

  &__body {
    width: 200px;
    height: 117px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 6px;
    cursor: pointer;
  }

  &__placeholder {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #f4f8fb;
    border: 1px solid #dee4ea;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .upload-placeholder-icon {
      margin-bottom: 8px;
      display: block;
      svg {
        width: 18px;
        height: 18px;
      }
    }

    .upload-placeholder-text {
      font-size: 13px;
      color: #3e464e;
    }
  }

  &__footer {
    &.hidden {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    .ant-btn {
      min-height: unset;
      width: unset;
      min-width: unset;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      svg {
        margin-right: 8px;
      }
    }
  }
}

.survey-pad-view__uploads {
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  gap: 24px;
  justify-content: center;
}
