@import '~antd/dist/antd.less';

// Variables overrides
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
@primary-color: #88173f;
@border-radius-base: 4px;
@font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
@font-size-base: 12px;
@menu-item-font-size: 16px;
@progress-default-color: #fadb14;
@collapse-panel-border-radius: 0;
@collapse-header-bg: #fafafa;

// react-phone-input-2 styles overrides with antd theme
.phone-input-container .ant-input {
  width: 100%;
  height: initial;
  line-height: inherit;
}
.ant-input:hover ~ .flag-dropdown,
.ant-input:focus ~ .flag-dropdown {
  border-color: @primary-color;
  transition: all 0.3s;
}

// Form.Item label
.ant-form-item-label {
  white-space: initial;
  overflow: initial;
}

// Select-tags fix for small size
.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 19px;
  line-height: 16px;
}

// Overwrite, emboldened selected tab
.ant-menu-item-selected {
  font-weight: bold;
}

.ant-collapse {
  border: 0;

  .ant-collapse-item {
    border: 0;
    .ant-collapse-header {
      font-size: 14px;
      font-weight: bold;
      .ant-collapse-arrow {
        top: 5px;
      }
    }
    .ant-collapse-content {
      border: 0;
    }
  }
}

.ant-image-preview-mask {
  z-index: 1002;
  background-color: rgba(0, 0, 0, 0.6);
}

.ant-image-preview-operations {
  background-color: rgba(0, 0, 0, 0.4);
}
