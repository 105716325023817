.announcements-view {
  .announcements-view-content[data-archived='true']::after {
    content: 'Archived';
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 42px);
    z-index: 3;
    background-color: rgba(#fff, 0.75);
  }
  .ant-skeleton {
    height: 40px;
  }
  .ant-skeleton-content {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
  }
  .ant-skeleton-title {
    margin: 0;
    width: 180px !important;
    height: 24px;
  }
  .page-main-content {
    padding: 24px 30px !important;
    margin-bottom: 10px;
    .ant-tabs {
      overflow: unset;
    }
  }
}

.accent-svg {
  path {
    fill: #88173f;
  }
}

.announcements-content {
  &_items {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    transition: 0.15s ease-in;
  }
  &_actions {
    position: absolute;
    top: 10px;
    right: 40px;
    display: flex;
    align-content: center;
    justify-content: flex-end;
  }
  &_toggle {
    position: absolute;
    top: 15px;
    right: 0px;
  }
}

.announcements-view_info {
  position: absolute;
  right: 28px;
  top: 28px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
}

.announcements-view_name {
  font-size: 18px;
  font-weight: 500;
  height: 40px;
}

.announcements-content_items.disabled {
  gap: 0px;

  .announcements-item {
    pointer-events: none;
    transform: translateX(calc(-50% * var(--i))) scale(0.95);
  }
}

.announcements-content.updated {
  .announcements-content {
    &_items {
      margin-bottom: 20px;
    }
  }
}

.announcements-item {
  flex: 1;
  position: relative;
  max-width: 215px;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: 0.15s;
  &[data-disabled='true'] {
    pointer-events: none;
  }
  &.preview {
    transform: rotate(-3deg);
  }
  &.drag {
    background: linear-gradient(90deg, #88173f 50%, transparent 50%),
      linear-gradient(90deg, #88173f 50%, transparent 50%),
      linear-gradient(0deg, #88173f 50%, transparent 50%),
      linear-gradient(0deg, #88173f 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 16px 1px, 16px 1px, 1px 16px, 1px 16px;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
    animation: dash 5s linear infinite;
    .image {
      opacity: 0;
    }
  }

  .add-layer-btn {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  .remove-layer-btn {
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: -12px;
    right: -12px;
    padding: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .name {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .image {
    width: 100%;
    max-width: 215px;
    height: 100%;
    max-height: 328px;
    min-height: 315px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    overflow: hidden;
    transition: 0.15s ease-in;
    img {
      max-width: 100%;
      object-fit: contain;
    }
  }
}

.announcements-item {
  & > span {
    width: 100%;
  }
  .ant-upload.ant-upload-drag .ant-upload-btn,
  .ant-upload.ant-upload-drag .ant-upload-drag-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  .ant-upload.ant-upload-drag .ant-upload-btn {
    position: relative;
    overflow: hidden;
  }
  .ant-upload {
    width: 100%;
    height: 328px;
    padding: 0;
  }
  .description {
    font-size: 11px;
    font-style: italic;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 10px;
  }
  .upload-bar,
  .upload-bar_progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    width: 100%;
    background-color: #d9d9d9;
    border-radius: 0 0 4px 4px;
    transition: 0.15s ease-in;
    opacity: 0;
    &[data-loading='true'] {
      opacity: 1;
    }
  }
  .upload-bar_progress {
    background-color: #88173f;
    width: 0%;
    transition: 0.15s ease-in;
  }
  .ant-btn-loading-icon {
    line-height: 1;
  }
}

.announcements-content_carousel {
  .ant-modal {
    width: 328px !important;
    min-width: 328px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-body {
    padding: 0;
  }
  .slick-slide {
    max-height: 680px;
    border-radius: 4px;
    overflow: hidden;
    .image {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .slick-dots {
    gap: 6px;
    li {
      margin: 0;
      width: unset;
      overflow: unset;
      button {
        border-radius: 50%;
        height: 8px;
        width: 8px;
        transition: 0.2s ease !important;
        background-color: #ccc;
      }
      &.slick-active {
        width: unset;
        overflow: unset;
        button {
          background-color: #88173f;
        }
      }
    }
  }
  .slick-arrow {
    z-index: 9;
    font-size: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    &::after {
      content: '';
      position: absolute;
      background-size: 16px;
      width: 100%;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-repeat: no-repeat;
    }
  }
  .slick-prev {
    left: -35px;
    &::after {
      content: '';
      background-position: left;
      background-image: url(../../assets/icons/angle-left.svg);
    }
  }
  .slick-next {
    right: -35px;
    &::after {
      content: '';
      background-position: right;
      background-image: url(../../assets/icons/angle-right.svg);
    }
  }
}

.announcements-item .image.skeleton {
  background: linear-gradient(
    90deg,
    rgba(190, 190, 190, 0.2) 25%,
    rgba(129, 129, 129, 0.24) 37%,
    rgba(190, 190, 190, 0.2) 63%
  );
  background-size: 400% 100%;
  height: 328px;
  animation: ant-skeleton-loading 1.4s ease infinite;
}

@keyframes dash {
  to {
    background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
  }
}

@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
