.uploads_wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #fff;
    z-index: 99;
    opacity: 0; } }

.photoTitle {
  a {
    color: #fff;
    text-decoration: underline; }
  span:not(:last-of-type)::after {
    content: ", "; } }
