$black: #000;
$white: #fff;

// Antd variables;
// These are only copies;
// Look in "/less/antd.less" to override theme variables;
$primary-color: #88173F;
$success-color: #52c41a;
$warning-color: #faad14;
$error-color: #f5222d;
$normal-color: #d9d9d9;

// Custom colors
$wild-sand: #F6F6F6;
$madang: #C4F7C4;
$azalea: #F7C4C4;
