.owners-list-item-action {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  svg {
    margin-right: 6px;
  }
}
