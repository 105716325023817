.survey-form {
  .ant-input-number {
    width: 100%;
  }

  .survey-checkbox-list {
    .ant-checkbox-wrapper {
      margin-bottom: 0;
      & + .ant-checkbox-wrapper {
        margin-left: 0;
      }
    }
  }

  .ant-descriptions-item-label {
    font-size: 14px;
    font-weight: 500;
  }

  .survey-view-step {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: start;
    gap: 10px;
  }
}

.survey-view-step__box {
  .box-title {
    font-weight: 500;
    margin-bottom: 8px;
    font-size: 13px;
  }

  .box-image {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 8px;
    width: 224px;
    height: 224px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 1 / 1;
    }
  }
}
