.user-pads-filters {
  padding: 24px 0 10px 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  h3 {
    margin: 0 15px 0 0; } }

.cell-fixed-width {
  .ant-table-cell:nth-of-type(5) {
    width: 34%; } }

.custom-btn {
  padding: 0;
  white-space: normal;
  text-align: left;
  position: relative;
  &.custom-icon {
    padding-left: 22px; }
  .icon {
    line-height: 1;
    position: absolute !important;
    left: -5px;
    top: 50%;
    transform: translateY(-50%);
    svg {
      max-width: 18px; }
    &.grey {
      color: #333; } } }

.custom-icon.private {
  .icon {
    color: #06f; } }

.ant-modal-mask, .ant-modal-wrap {
  z-index: 1001; }

.ant-modal-wrap {
  & > .ant-modal {
    min-width: 470px; } }

.pad-submissions {
  .ant-table-wrapper {
    padding: 20px 0; } }

.pad-submissions-filters {
  padding: 16px;
  background-color: #fff;
  .ant-col.ant-form-item-control {
    min-width: 188px; } }

.duplicate-table-with-summary, .pad-submissions {
  .ant-spin-container {
    .ant-pagination-total-text {
      color: #d9d9d9;
      position: absolute; }

    .ant-table-pagination {
      position: relative; }

    .ant-table-pagination:first-child {
      .ant-pagination-total-text {
        top: -40px; } }

    .ant-table-pagination:last-child {
      .ant-pagination-total-text {
        bottom: -40px; } } } }

.ant-upload.ant-upload-select-picture-card {
  width: 140px;
  height: 98px;
  margin: 0;
  border-style: solid;
  border-width: 2px; }

.ant-upload-list-picture-card-container {
  margin: 0;
  width: 140px !important;
  height: unset;
  position: relative;

  &:hover {
    .ant-btn {
      opacity: 1; } }

  .ant-btn {
    min-width: unset;
    min-height: unset;
    padding: 0;
    color: $primary-color;
    font-weight: 600;
    width: 100%;
    font-size: 16px;
    opacity: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-color: rgba(#fff, .8); }

  .picture-image-container {
    position: relative;
    &.is-dragging {
      .picture-image-preview {
        border: 2px dashed #c0c0c0;
        img {
          opacity: 0; } } } }

  .picture-image-preview {
    width: 140px;
    height: 98px;
    background-color: #f4f4f4;
    border: 1px solid #e4e4e4;
    overflow: hidden;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%; }

    &__btn {
      cursor: pointer; }

    &__error {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 12px;
      color: $error-color;
      text-align: center;
      font-weight: 500; } } }

.ant-upload-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 140px));
  gap: 10px;
  justify-content: start;
  &::before {
    display: unset;
    content: unset; } }

.fullsizeReport {
  &.review {
    .ant-modal {
      width: 750px !important; }
    .ant-modal-body {
      padding: 15px; } }
  .ant-modal {
    width: 620px !important; }
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .fillsize-content_image {
    max-width: 720px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 90vh;
    overflow: hidden;
    img {
      max-width: 100%;
      max-height: 90vh; } } }

@import "./components/reviewElement.scss";

.duplicate-list {
  padding: 12px 20px;
  display: flex;
  justify-content: space-between; }

.duplicate-list {
  &-filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .ant-slider {
      width: 200px;
      margin: 10px 0; }
    .label {
      margin-bottom: 6px; } }
  &-swt {
    display: flex;
    align-items: center;
    .label {
      margin-left: 6px; } } }

.dupe-line-through {
  text-decoration: line-through; }

.dupe-gray {
  color: #ccc; }
