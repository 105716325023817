.review {
  &-inner {
    display: flex;
    flex-direction: column;
    &_user {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      padding: 0 10px;
    }
    &_content {
      width: 100%;
      border-radius: 30px;
    }
    &_report {
      position: absolute;
      bottom: 25px;
      right: 20px;
      .ant-btn::before {
        background: unset;
      }
    }
  }

  .review-inner_content {
    background-color: #f8f8f8;
    padding: 20px 25px;
    font-size: 14px;
    position: relative;
    align-self: flex-end;
    border-radius: 30px 30px 0 30px;
    border: 2px solid #ececec;
    position: relative;
    width: calc(100% - 10px);
    .line-to {
      position: absolute;
      right: calc(100% + 2px);
      border-top: 2px solid #e6e6e6;
      border-left: 2px solid #e6e6e6;
      border-top-left-radius: 30px;
      height: 70%;
      width: 15px;
      top: 30px;
    }
    .line-from {
      position: absolute;
      right: calc(100% + 2px);
      width: 65px;
      height: 50%;
      top: 3px;
      transform: translateY(-50%);
      border-bottom: 2px solid #e6e6e6;
      border-left: 2px solid #e6e6e6;
      border-bottom-left-radius: 30px;
    }
    &.report {
      width: calc(100% - 60px);
      border-radius: 0 0 30px 30px;
      border-top: none;
      background-color: rgba(#ffe2e5, 0.5);
      .content-comment {
        margin-bottom: 0;
      }
    }
    .user-meta {
      font-size: 14px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .name {
        text-transform: capitalize;
        color: $primary-color;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .role {
          color: #848b92 !important;
          font-weight: 400;
          margin-left: 15px;
          font-size: 12px;
          line-height: 15px;
          position: relative;
          display: block;
          pointer-events: none;
          text-decoration: none !important;
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(calc(-50% + 0.5px));
            width: 4px;
            height: 4px;
            background-color: #b3b3b3;
            border-radius: 50%;
            left: -9px;
          }
        }
        .icon {
          margin-right: 6px;
          transform: translateY(-1.5px);
          img {
            width: 14px;
          }
        }
      }
      .created {
        font-weight: 500;
        font-size: 13px;
      }
    }
    .content-comment {
      margin-bottom: 20px;
    }
  }
}

.rate-inline-container {
  width: 100%;
  justify-content: space-between;
  .ant-rate-star {
    svg {
      width: 16px;
    }
  }
  ul.ant-rate {
    li.ant-rate-star:not(:last-child) {
      margin-right: 5px;
    }
  }
  .rate-and-title-element {
    &:not(:last-child) {
      margin-right: 25px;
    }
    .rate-value {
      margin-left: 7px;
      width: auto;
      min-width: auto;
    }
  }
}

.rate-block-container {
  background: #f4f8fb;
  padding: 25px 29px;
  border-radius: 4;

  ul.ant-rate {
    li.ant-rate-star:not(:last-child) {
      margin-right: 15px;
    }
  }

  .rate-and-title-element:not(:last-of-type) {
    margin-bottom: 30px;
  }
}

.rate-and-title-element {
  .rate-value {
    color: #848b92;
    font-size: 14px;
    font-weight: 500;
    margin-left: 20px;
    width: 50px;
    min-width: 50px;
    text-align: right;
  }
  .rate-label {
    color: #000;
    font-size: 13px;
  }
}

.review-reason {
  position: absolute;
  right: -5px;
  left: -5px;
  top: calc(100% + 5px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  pointer-events: none;
  .checkbox {
    display: inline-block;
    background-color: #f8f8f8;
    padding: 3px 10px;
    border-radius: 4px;
    border: 2px solid #ececec;
    font-weight: 600;
    margin: 5px;
  }
}

@media ($max-lg) {
  .rate-inline-container {
    .rate-and-title-element {
      &:not(:last-child) {
        margin-right: 35px;
      }
    }
  }
}

@media ($max-md) {
  .rate-inline-container {
    flex-direction: column;
    .rate-and-title-element {
      &:not(:last-child) {
        margin-bottom: 15px;
        margin-right: 0;
      }
    }
  }
}

@media ($max-sm) {
  .rate-block-container {
    .rate-and-title-element {
      flex-direction: column;
      align-items: flex-start !important;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
