@import "../../sass/utils";

.root {
  height: 100vh;
  background: linear-gradient(to bottom, rgba($white, 0.1), rgba(#1c5896, 0.2)), $primary-color url("../../assets/img/patrick-tomasso-HM731qUoUas-unsplash.jpg") no-repeat top center / cover; }

.form {
  width: 100%;
  max-width: 350px;
  background-color: $white;
  border-radius: 4px;
  padding: 2rem;
  box-shadow: 0 15px 25px rgba($black, 0.6); }
