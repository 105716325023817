.import-pad-errors {
  .import-pad-error:not(:last-of-type) {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ffccc7;
  }
  .import-pad-error {
    span {
      display: block;
    }
  }
}
