.inline-edit-wrapper {
  display: inline-flex;
}

.inline-edit-static {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  height: 40px;

  h3 {
    margin-bottom: 0;
    font-size: 18px;
  }
}
