.export-filters-btn {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

.export-filters-btn-placeholder {
  width: 100%;
  height: 24px;
}
