.pad-view-tabs {
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
    margin-right: 0;
  }
}

.bool-success {
  color: #52c41a;
}

.bool-error {
  color: #f5222d;
}

.premium-user-icon {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 1;
}

.pad-submitters-list .submitter-link:not(:last-of-type)::after {
  content: ',\00a0';
}

.create-owner {
  .ant-modal-wrap > .ant-modal {
    min-width: 380px;
  }
}

.owners {
  min-height: 218px;
  max-height: 397px;
  overflow-y: auto;
}

.timeline-container {
  border-color: #d9d9d9;
  display: flex;
  flex-direction: column;
  min-height: 220px;
  &__custom {
    min-height: unset;
    .ant-card-body {
      padding: 0;
    }
  }
  .ant-card-head-title {
    flex-grow: 1;
    padding: 12px 0;
  }
  .ant-card-body {
    flex-grow: 1;
    overflow-y: auto;
    max-height: 607px;
    min-height: 0;
  }
  .ant-timeline-item-last > .ant-timeline-item-content {
    min-height: auto;
  }
}
