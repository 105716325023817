.campaign-title {
  h3 {
    color: #88173f;
    line-height: 1;
    border-bottom: 2px solid transparent;
  }
  &:hover {
    h3 {
      border-bottom: 2px solid #88173f;
    }
  }
}
