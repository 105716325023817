@import "../../../../sass/utils";

.commentsCard {
   :global(.ant-card-body) {
    padding: 24px 12px; } }

.comment {
  padding: 12px;
   :global(.ant-list-item-meta-title) {
    margin: 0; }
  &:global(.inactive) {
    background-color: $wild-sand;
     :global(.ant-list-item-meta-title) {
      svg path {
        fill: #333; } }
     :global(.ant-list-item-main) {
      opacity: 0.5;
      pointer-events: none; } }
   :global(.attachments) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 15px; }
   :global(.attach-item) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 140px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
     :global(img) {
      max-width: 100%; } } }

.listSpinner {
  min-height: 115px; }

.reviewUpdatedAt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-size: 12px;
  transform: translateY(1px);
  color: rgba(0, 0, 0, 0.45);
  svg {
    width: 11px;
    height: 11px;
    margin-right: 5px;
    transform: translateY(-1px); } }
