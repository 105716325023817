code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

*:focus, input:focus, button:focus {
  outline: none; }

.page-main-header {
  padding: 0;
  margin-bottom: 2rem;
  &.no-children {
    margin-bottom: 12px; } }

.page-main-content {
  margin-bottom: 2rem;
  position: relative;
  min-width: 992px;
  &-bar {
    position: absolute !important;
    top: 0;
    left: 0; } }

.mt-6 {
  margin-top: 54px; }
.mb-2_5 {
  margin-bottom: 2rem; }

.summary-container {
  margin-bottom: 2rem; }

.summary-el {
  background-color: #fafafa;
  padding: 4px 9px;
  margin: 2px 2px;
  white-space: nowrap;
  border: none;
  transition: background-color 0.3s ease;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;

  &.isSelected {
    background-color: #ededed;
    border-color: rgba(#943150, .5); }

  &:hover {
    background-color: #ededed; } }

.ReactModal__Body--open {
  overflow: hidden; }

.page-main-content, .summary-container {
  background-color: $white;
  padding: 24px; }

.font-weight-semi-bold {
  font-weight: 600!important; }

.sm-container {
  @extend %sm-container; }

.lg-container {
  @extend %lg-container; }

.transparent-el {
  opacity: .4; }

.fixed-to-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%); }

.bullet-point {
  height: 10px;
  width: 10px;
  background: #943150;
  border-radius: 50%; }

span.error {
  color: #F64E60;
  font-weight: 500; }

.ant-btn.error {
  path {
    fill: #F64E60; } }

.prev-is-conflict-row {
  background: #e1a0a0;
  margin-bottom: 5px;
  margin-left: -5px;
  margin-right: -5px;
  padding: 0 5px;
  border-radius: 5px; }

.next-is-conflict-row {
  background: #9add9a;
  margin-bottom: 5px;
  margin-left: -5px;
  margin-right: -5px;
  padding: 0 5px;
  border-radius: 5px; }

[class^="duplicatesResolver"] {
  .remove-img-btn {
    display: none; } }

.scroll-persist .rc-virtual-list-scrollbar {
  display: block !important; }

.ant-table-wrapper.no_dates .ant-empty-description {
  color: rgba(#000, .5); }

.ant-descriptions-item-content.ant-descriptions-item-content_flex {
  display: flex;
  align-items: center; }

.pad-name_prefix {
  transform: translateY(-1px);
  margin-right: 5px; }

.import-pad_example {
  .ant-collapse {
    border: 1px solid #d9d9d9;
    border-bottom: 0;
    border-radius: 4px; }
  .ant-collapse-item {
    border-bottom: 1px solid #d9d9d9;
    .ant-collapse-content {
      border-top: 1px solid #d9d9d9; } }
  .ant-upload.ant-upload-select {
    display: block; } }

.import-pad_list {
  .ant-list-item {
    font-size: 13px; } }

.toggle-pads-view {
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid rgba(#000, .1);
  background-color: #fff;
  cursor: auto;
  user-select: none;
  transition: .25s ease-in;
  box-shadow: 0 2px #0000000b;
  margin-bottom: 12px;
  &:hover {
    border-color: #88173f; } }

.accent-icon {
  path {
    fill: #FFA800; } }

.archived-row {
  text-decoration: line-through !important;
  .ant-btn span {
    text-decoration: line-through !important; } }

.direction-relative-box {
  position: relative;
  div > .image {
    .direction {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      z-index: 1; } } }

.direction-relative-top-right {
  position: relative;
  .image {
    .direction {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1; } } }

.scroll-custom .ant-table > .ant-table-container > .ant-table-content {
  &::-webkit-scrollbar {
    width: 5px;
    height: 7px; }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #fff; }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(#88173f, 1);
    border-radius: 5px; }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(#88173f, .5);
    border-radius: 5px; } }

.bg-white {
  background-color: $white; }
