.survey-upload {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fafafa;
  border: 1px solid #f0f0f0;
  padding: 6px;
  border-radius: 4px;
  min-width: 184px;
}

.survey-upload-img {
  width: 64px;
  height: 64px;
  min-width: 64px;
  min-height: 64px;
  border-radius: 4px;
  overflow: hidden;
  background-color: #ebebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: cover;
    cursor: pointer;
    width: 100%;
  }
}

.survey-upload-caption {
  margin-left: 8px;
  padding-top: 1px;
  flex: 1;
  h4 {
    font-weight: 500;
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
}

.survey-uploads-grid {
  padding: 0.25rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(184px, 1fr));
  grid-template-rows: auto;
  gap: 0.25rem;
  &.editing {
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - 0.25rem), 1fr));
  }
}

.survey-upload.editing {
  align-items: flex-start;
  .survey-upload-caption {
    h4 {
      margin-bottom: 4px;
    }
  }
  .survey-upload-img button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    border: 1px dashed rgba(#000, 0.3);
    border-radius: 4px;
    cursor: pointer;
  }
}

.li-100 {
  li {
    width: 100% !important;
  }
}

.step-border-none {
  border-bottom: 1px solid #000;
}
